.dot-loader {
  color: var(--dpzBlue);
  text-align: center;
}

@keyframes dot-swipe {
  to {
    transform: translateX(100%);
  }
}

.bouncy .bouncy__dot {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  margin: 0 0.125em;
  animation: bouncey-bounce 1.25s infinite ease-in-out both;
}

.bouncy .bouncy__dot:nth-child(1) {
  animation-delay: -0.3s;
}

.bouncy .bouncy__dot:nth-child(2) {
  animation-delay: -0.15s;
}

.bouncy .bouncy__dot--red {
  background-color: var(--dpzRed);
}

.bouncy .bouncy__dot--blue {
  background-color: var(--dpzBlue);
}

.bouncy .bouncy__dot--white {
  background-color: #fff;
}

.bouncy .bouncy__dot--black {
  background-color: #000;
}

@keyframes bouncey-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
