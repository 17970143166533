@import "pikaday/css/pikaday.css";
@import "pikaday/css/triangle.css";
@import "foreign-object";

/* fo-colors */
:root {
  --dpzRed: #ce253d;
  --dpzBlue: #0078ae;
  --attention: #995c00;
  --attentionBg: #feefb3;
  --positive: #44750f;
  --positiveBg: #dff2bf;
  --negative: #ad0009;
  --negativeBg: #ffbaba;
  --subtle: #686868;
  --subtleBg: #eee;
  --disabled: #aaa;
  --disabledBg: #ddd;

  --statusAttention: {
    color: var(--attention);
    background-color: var(--attentionBg);
  }
  --statusPositive: {
    color: var(--positive);
    background-color: var(--positiveBg);
  }
  --statusNegative: {
    color: var(--negative);
    background-color: var(--negativeBg);
  }
  --statusSubtle: {
    color: var(--subtle);
    background-color: var(--subtleBg);
  }
  --statusDisabled: {
    color: var(--disabled) !important;
    background-color: var(--disabledBg) !important;
    cursor: not-allowed !important;
  }

  --attention-bg: var(--attentionBg);
  --attention-fg: var(--attention);
  --bg: #fafafa;
  --border: rgba(0, 85, 128, 0.15);
  --default-bg: #d5dfe3;
  --default-fg: #5e5e5e;
  --dom-blue: var(--dpzBlue);
  --dom-red: var(--dpzRed);
  --fg: #444;
  --fill-left: linear-gradient(
    to right,
    transparent,
    transparent 50%,
    var(--fg) 50%
  );
  --fill-right: linear-gradient(
    to left,
    transparent,
    transparent 50%,
    var(--fg) 50%
  );
  --h-border: var(--border);
  --interactive-bg: #d6edfa;
  --interactive-fg: var(--dpzBlue);
  --interactive-hover: rgba(0, 120, 174, 0.050980392156862744);
  --negative-bg: var(--negativeBg);
  --negative-fg: var(--negative);
  --positive-bg: var(--positiveBg);
  --positive-fg: var(--positive);
  --subtle-bg: var(--subtleBg);
  --subtle-fg: var(--subtle);
  --stjude-bg: #c0d72d;
  --stjude-fg: #555;
}
