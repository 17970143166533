:root {
  --border: #dbe9f0;
}

.type-ahead__wrapper {
  position: relative;
}

.type-ahead__options {
  background-color: #fff;
  border: 1px solid var(--border);
  border-bottom-width: 0;
  border-top-width: 0;
  position: absolute;
  width: calc(100% - 0.142857143rem);
  z-index: 1;
  max-height: 25em;
  overflow-y: auto;
}

.type-ahead__option {
  border-bottom: 1px solid var(--border);
  cursor: pointer;
  padding: 1rem;
}

.type-ahead__option:hover {
  color: var(--dpzRed);
}
