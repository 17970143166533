.pin-drop__map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pin-drop__wrapper {
  width: 100%;
  height: 0;
  margin: 0;
  overflow: visible;
  padding-bottom: 60%; /* Determines map aspect ratio, e.g. 50% yields a 2:1 map */
  position: relative;
}
