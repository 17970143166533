.form-controls {
  margin-bottom: 1.75rem;
  margin-top: 0.5rem;
}

.locator-other-option {
  text-align: center;
  margin-top: 0.25em;
  margin-bottom: 1em;
  font-weight: 700;
  text-transform: uppercase;
}

.locator-other-option::after {
  content: "–";
  margin-left: 0.5em;
}

.locator-other-option::before {
  content: "–";
  margin-right: 0.5em;
}
