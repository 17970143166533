.form-controls {
  margin-bottom: 1.75rem;
  margin-top: 0.5rem;
}

.locations__buildings {
  position: relative;
}

.prepend-slash::after {
  content: "";
  border-right: 1px solid black;
  position: absolute;
  width: 8px;
  height: 25px;
  transform: rotate(20deg);
}

.address-or-text {
  width: 2.5%;
  vertical-align: bottom;
  line-height: 30px;
  font-weight: 700;
  position: relative;
  left: -4px;
}
